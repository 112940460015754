<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <label class="placeholder placeholder-inactive">Client Company</label>
        <v-select
          placeholder="Select companies"
          ref="clientCompanies"
          :options="clientCompanies"
          label="name"
          v-model="clientCompany"
          @update:modelValue="$emit('update:parameters', { ...parameters, 'client_company_id': clientCompany ? clientCompany.id : '' })"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <DamageClassification
          ref="ratings"
          placeholder="Rating"
          name="rating"
          id="rating"
          :default-rating="ratings"
          :ratings="[0, 1, 2, 3, 4, 5]"
          @changeRating="setRating"
        ></DamageClassification>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import DamageClassification from "../../shared/filter/damage_classification";

export default {
  components: {
    vSelect,
    DamageClassification,
  },

  props: {
    parameters: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      clientCompanies: this.clientCompanies,
      clientCompany: null,
      ratings: [],
    }
  },

  mounted: function() {
    this.fetchClientCompanies();
  },

  methods: {
    setRating: function(selectedRating) {
      this.ratings = selectedRating;
      this.$emit('update:parameters', { ...this.parameters, rating: selectedRating });
    },

    fetchClientCompanies: function() {
      let fetchParams = {
        orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.clientCompanies = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.clientCompanies",
          fetchParams,

          (data) => {
            let clientCompanies = data.data.clientCompanies,
                pageInfo = clientCompanies.pageInfo;

            success(clientCompanies, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Client Companies!"
            });
          }
        )
      };

      let getClientCompanies = (cursor) => {
        fetch(
          cursor,

          (clientCompanies, pageInfo) => {
            clientCompanies.edges.forEach((edge) => this.clientCompanies.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getClientCompanies(pageInfo.endCursor))
            }
          }
        )
      };

      getClientCompanies(window.btoa(0));
    },
  }
}
</script>
